// jQuery plugin to prevent double submission of forms
// customised from - https://stackoverflow.com/questions/2830542/prevent-double-submission-of-forms-in-jquery
(function($) {

  $.fn.preventDoubleSubmission = function(validator) {

    $(this).on('submit', function(e) {
      var $form = $(this);
      if ($form.data('submitted') === true) {
        // Previously submitted - don't submit again
        e.preventDefault();
        return;
      } else {
        // Mark it so that the next submit can be ignored
        $form.data('submitted', true);
      }
      
      if (typeof validator !== "undefined") {
        if (!validator(this)) {
          e.preventDefault();
          // unmark it so that the next submit won't be ignored
          $form.data('submitted', false);
        }
      }

    });

    // Keep chainability
    return this;
  };

}(jQuery));
